<script setup lang="ts">import { ref as _ref } from 'vue';

import { NuxtImg } from '#components'

definePageMeta({ title: '烟花绽放 浴火新生' })

const video = _ref<HTMLVideoElement>()

// fallback for browsers that don't support autoplay
// e.g. Safari on iOS when power saving mode is on
let interval: NodeJS.Timeout

onActivated(() => {
  if (!video.value?.paused)
    return

  interval = setInterval(() => {
    if (!navigator.userActivation?.hasBeenActive)
      return

    try {
      video.value.play()
    }
    catch {}

    clearInterval(interval)
  }, 100)
})

onDeactivated(() => clearInterval(interval))

const testimonials = [
  {
    quote:
      `${''
      }非常非常喜欢Hanabi_Live，在这里每一个小小想法都可以得到小伙伴们的回应，肯定或者建议对我来说都非常珍贵！${''
      }（深夜聊天总是能勾起对同事的食欲（bushi${''
      }`,
    author: {
      name: '橘莱米',
      description: 'Hanabi_Live一期生',
      to: '/member/rayme',
      avatar: {
        as: NuxtImg,
        src: 'b0349e20561fa39823ac8fedceee9c1b.png',
        width: 80,
      },
    },
  },
  {
    quote: '希望大家都可以健健康康平平安安，做好朋友一辈子，这样就能80你们久一点咯！',
    author: {
      name: '池乐还是有点饿',
      description: 'Hanabi_Live一期生',
      to: '/member/chile',
      avatar: {
        as: NuxtImg,
        src: '9d7ce2f80e1113488ed850fa65752e76.png',
        width: 80,
      },
    },
  },
  {
    quote:
      `${''
      }自从进了Hanabi_Live，再也不愁没人一起聊天一起打瓦了。${''
      }在迷茫的时候也会有人一起讨论方向，是很温暖的小家庭。${''
      }希望能够早日发展成温暖大家庭嘎！${''
      }`,
    author: {
      name: '夕雁Yukari',
      description: 'Hanabi_Live成员',
      to: '/member/yukari',
      avatar: {
        as: NuxtImg,
        src: '3bd5a5a847a2b782367d789f7ee22eaf.png',
        width: 80,
      },
    },
  },
  {
    quote:
      `${''
      }特大消息，特大消息！${''
      }自从进入Hanabi_live以来，我腰不酸了，腿不疼了，作息健康了，睡不着的时候甚至还有无可匹敌的温柔系福利社长哄睡服务！${''
      }聊天频道时刻充斥着各类宝藏，不开心的时候主动沟通共同成长！${''
      }只要你自己努力，多方位关心更适合虚拟宝宝成长方式！\n${''
      }PS：想被骂着成长欢迎寻找敖雾AOU，惹毛了的时候脾气臭的冲出宇宙x${''
      }`,
    author: {
      name: '敖雾AOU',
      description: 'Hanabi_Live成员',
      to: '/member/aou',
      avatar: {
        as: NuxtImg,
        src: 'e996175943446c31f430bea472006879.png',
        width: 80,
      },
    },
  },
  {
    quote:
      `${''
      }真的很喜欢在Hanabi和大家一起工作！${''
      }虽然日表催不动周表更别想（bushi），但是大家都是很好很好的人，有的时候晚上一起聊天哪怕只是静静地听着也很开心，感觉就像家一样~${''
      }`,
    author: {
      name: '某不知名的Staff星棠',
      description: 'Hanabi_Live Staff',
      to: '/member/starry',
      avatar: {
        as: NuxtImg,
        src: 'd9b3f76de7297233006cc724acf3d81d.png',
        width: 80,
      },
    },
  },
]
</script>

<template>
  <div>
    <!-- @vue-expect-error Vue can't normally set muted attribute -->
    <video
      ref="video"
      class="
        absolute top-0 w-full h-screen object-cover
        blur-[1px] brightness-75 dark:brightness-50
      "
      :muted.attr="''"
      autoplay
      loop
      playsinline
    >
      <source
        src="https://static.hanabi-live.com/assets/601da2566d293b466fe48f56342960eb.mp4"
        media="(orientation: portrait)"
      >
      <source
        src="https://static.hanabi-live.com/assets/686e1cc2ffc7037ce45d77d525c631d4.mp4"
      >
    </video>

    <ULandingHero
      class="
        dark absolute top-0 bottom-0 m-auto w-full
        flex justify-center items-center opacity-75
      "
      :ui="{ container: 'w-full', title: 'relative max-w-screen-sm mx-auto' }"
    >
      <template #headline>
        <UButton
          color="primary"
          variant="outline"
          to="/dashboard/order"
          label="礼物系统上线啦！"
          trailing-icon="heroicons:arrow-right"
          size="xs"
          class="rounded-full font-bold"
        />
      </template>

      <template #title>
        <NuxtImg
          class="w-[85.5%] right-[-18%] relative animate-pulse"
          src="dbdaa2f8f9897e793ca6c7bb49575a99.png"
        />

        <NuxtImg
          class="absolute top-0 bottom-0 w-full m-auto"
          src="1441947b49b2e92d64c51acceeaf993b.png"
        />
      </template>

      <template #description>
        <p class="font-bold text-3xl sm:text-4xl">
          烟花绽放 浴火新生
        </p>
      </template>

      <template #bottom>
        <Icon
          class="absolute bottom-0 mx-auto text-white animate-bounce"
          name="material-symbols:keyboard-arrow-down-rounded"
          size="64"
        />
      </template>
    </ULandingHero>

    <div class="h-[calc(100vh-var(--header-height))]" />

    <ULandingHero
      title="礼物系统上线啦！"
      description="礼物系统即日起正式上线，赶快去领取你的大航海礼物吧！"
      orientation="horizontal"
      :links="[{
        color: 'primary',
        to: '/dashboard/order',
        label: '点击前往',
        trailingIcon: 'heroicons:arrow-right',
        size: 'lg',
      }]"
      :ui="{ base: 'order-last flex flex-col flex-wrap content-center' }"
    >
      <NuxtImg
        class="mx-auto max-h-[calc(100vh-var(--header-height))] rounded-lg"
        src="https://i0.hdslb.com/bfs/article/a3851eef0da96cf61ee11c2bd8b300353546602868312291.png"
        referrerpolicy="same-origin"
      />
    </ULandingHero>

    <ULandingSection
      id="partners"
      title="我们的合作伙伴"
      description="优质的美术资源，丰富的视频资源，个性装扮、收藏集上架服务，专属的出道曲，大型活动参与机会，优质的虚拟社群制作资源……"
    >
      <ULandingLogos align="center" :ui="{ images: 'justify-around' }">
        <UButton
          class="h-40"
          color="gray"
          variant="ghost"
          size="xl"
          to="https://space.bilibili.com/1178470738"
          target="_blank"
        >
          <NuxtImg
            class="h-full dark:invert"
            src="7ffc5856f9ad8932054ea1ce5719c347.png"
          />
        </UButton>

        <UButton
          class="w-fit md:h-40"
          color="gray"
          variant="ghost"
          size="xl"
          to="https://space.bilibili.com/3493297045637304"
          target="_blank"
        >
          <NuxtImg class="h-full" src="1890477882296e00b5fa22492f8a3409.png" />
        </UButton>
      </ULandingLogos>
    </ULandingSection>

    <ULandingSection id="testimonials" title="社员买家秀">
      <UPageColumns
        class="xl:columns-4"
      >
        <div
          v-for="testimonial, index in testimonials"
          :key="index"
          class="break-inside-avoid"
        >
          <ULandingTestimonial v-bind="testimonial" />
        </div>
      </UPageColumns>
    </ULandingSection>

    <ULandingHero
      title="加入我们"
      description="嗨，未来的伙伴，准备好加入这场热烈而灿烂的旅行了吗？"
      align="center"
      :links="[
        { to: '/member', label: '成员名单', icon: 'tdesign:member', size: 'lg' },
        {
          to: '/blog/recruitment',
          label: '招募公告',
          icon: 'heroicons:newspaper',
          size: 'lg',
        },
      ]"
    >
      <NuxtImg
        class="
          order-first mx-auto w-full rounded-md shadow-xl
          ring-1 ring-gray-300 dark:ring-gray-700
        "
        src="c7f513906f8817de6c5a683c34525643.png"
      />
    </ULandingHero>
  </div>
</template>
